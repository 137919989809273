export const couponApi = api => ({
  create(name, slug, startDate, endDate) {
    return api.post('/coupon/create', {
      name,
      slug,
      startDate,
      endDate
    });
  },
  list(page) {
    return api.get(`/coupon/list/${page}`);
  },
  get(id) {
    return api.get(`/coupon/${id}`);
  },
  update(name, slug, startDate, endDate, id) {
    return api.post(`/coupon/update/${id}`, {
      name,
      slug,
      startDate,
      endDate
    });
  },
  delete(id) {
    return api.delete(`/coupon/delete/${id}`);
  }
});
