import React, { useEffect, useMemo } from 'react';
import { AppButton, AppInput, AppSelect } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function AddLeague() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;
  const [coupons, setCoupons] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    const init = async () => {
      const couponsData = [];
      await api.coupon.list(0).then(async res => {
        res.data.map(league => {
          couponsData.push({ value: league._id, label: league.name });
        });
        setCoupons(couponsData);
      });

      if (isEdit) {
        await api.match.get(id).then(async res => {
          const data = res.data;
          const editData = {
            name: data.name,
            coupon: couponsData.find(coupon => coupon.value === data.coupon),
            oddsType: data.oddsType,
            odds: data.odds,
            prediction: data.prediction
          };
          setData(editData);
          reset(editData);
        });
      }
    };
    init();
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.match[isEdit ? 'update' : 'create'](
      submitData.coupon.value,
      submitData.name,
      submitData.oddsType,
      submitData.odds,
      submitData.prediction,
      id
    )
      .then(async () => {
        navigate('/match/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Yeni Maç Ekle
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Yeni bir maç ekleyebilir ve bu maçı ilgili kuponlara ekleyebilirsiniz.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppSelect
          options={coupons}
          label="Kupon"
          name="coupon"
          error={errors.coupon}
          rules={{ required: true }}
          control={control}
          placeholder="Kupon Seçiniz"
        />
        <AppInput
          label="Maç Adı"
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{}}
          register={register}
          placeholder="Maç Adı"
        />
        <AppInput
          label="Oran Türü"
          name="oddsType"
          // @ts-ignore
          error={errors.oddsType}
          rules={{}}
          register={register}
          placeholder="Oran Türü"
        />
        <AppInput
          label="Oran"
          name="odds"
          // @ts-ignore
          error={errors.odds}
          rules={{}}
          register={register}
          placeholder="Oran"
        />
        <AppInput
          label="Tahmin"
          name="prediction"
          // @ts-ignore
          error={errors.prediction}
          rules={{}}
          register={register}
          placeholder="Tahmin"
        />{' '}
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
