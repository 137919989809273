import React, { useEffect, useMemo } from 'react';
import { AppButton, AppInput, MaskInput } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertToSlug } from 'utils/slug';
import { datePattern, timePattern } from '../../constants/patterns';
import { dateToString, timeToString } from '../../utils/date';

export default function AddCoupon() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    const init = async () => {
      if (isEdit) {
        api.coupon.get(id).then(async res => {
          const data = res.data;
          data.startTime = timeToString(data.startDate);
          data.startDate = dateToString(data.startDate);
          data.endTime = timeToString(data.endDate);
          data.endDate = dateToString(data.endDate);
          setData(res.data);
          reset(res.data);
        });
      }
    };
    init();
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    const startDate = submitData.startDate.split('/').reverse().join('-');
    const endDate = submitData.endDate.split('/').reverse().join('-');
    const slug = `${convertToSlug(submitData.name)}-${startDate.replaceAll(
      '-',
      ''
    )}`;

    await api.coupon[isEdit ? 'update' : 'create'](
      submitData.name,
      slug,
      new Date(`${startDate} ${submitData.startTime}`),
      new Date(`${endDate} ${submitData.endTime}`),
      id
    )
      .then(async () => {
        navigate('/coupon/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Yeni Kupon Ekle
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Yeni kupon eklemek için aşağıdaki formu doldurunuz. Eklediğiniz
        kuponları daha sonra düzenleyebilirsiniz. Kategori eklerken dikkat
        etmeniz gereken en önemli nokta, kuponların tarih aralığıdır.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Kupon Adı"
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder="Kupon Adı"
        />
        <MaskInput
          label="Kupon Başlangıç Tarihi"
          mask="99/99/9999"
          maskPlaceholder="GG/AA/YYYY"
          name="startDate"
          // @ts-ignore
          error={errors.startDate}
          rules={{ required: true, pattern: datePattern }}
          register={register}
          placeholder="Kupon Başlangıç Tarihi"
        />
        <MaskInput
          label="Kupon Başlangıç Saati"
          mask="99:99"
          maskPlaceholder="HH:MM"
          name="startTime"
          // @ts-ignore
          error={errors.startTime}
          rules={{ required: true, pattern: timePattern }}
          register={register}
          placeholder="Kupon Başlangıç Saati"
        />
        <MaskInput
          label="Kupon Bitiş Tarihi"
          mask="99/99/9999"
          maskPlaceholder="GG/AA/YYYY"
          name="endDate"
          // @ts-ignore
          error={errors.endDate}
          rules={{ required: true, pattern: datePattern }}
          register={register}
          placeholder="Kupon Başlangıç Tarihi"
        />
        <MaskInput
          label="Kupon Bitiş Saati"
          mask="99:99"
          maskPlaceholder="HH:MM"
          name="endTime"
          // @ts-ignore
          error={errors.endTime}
          rules={{ required: true, pattern: timePattern }}
          register={register}
          placeholder="Kupon Başlangıç Saati"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
