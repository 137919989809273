export const voteApi = api => ({
  list(page) {
    return api.get(`/vote/list/${page}`);
  },
  get(id) {
    return api.get(`/vote/${id}`);
  },
  delete(id) {
    return api.delete(`/vote/delete/${id}`);
  }
});
