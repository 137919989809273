export const matchApi = api => ({
  create(coupon, name, oddsType, odds, prediction) {
    return api.post('/match/create', {
      coupon,
      name,
      oddsType,
      odds,
      prediction
    });
  },
  list(page) {
    return api.get(`/match/list/${page}`);
  },
  archive(page) {
    return api.get(`/match/archive/${page}`);
  },
  get(id) {
    return api.get(`/match/${id}`);
  },
  update(coupon, name, oddsType, odds, prediction, id) {
    return api.post(`/match/update/${id}`, {
      coupon,
      name,
      oddsType,
      odds,
      prediction
    });
  },
  delete(id) {
    return api.delete(`/match/delete/${id}`);
  }
});
