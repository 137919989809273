import { apiConfig } from 'config';
import {
  authApi,
  userApi,
  couponApi,
  leagueApi,
  matchApi,
  siteAPi,
  countryApi,
  paymentApi,
  slideApi,
  voteApi
} from 'services';

const api = apiConfig;

api.auth = authApi(api);
api.user = userApi(api);
api.coupon = couponApi(api);
api.league = leagueApi(api);
api.match = matchApi(api);
api.site = siteAPi(api);
api.country = countryApi(api);
api.payment = paymentApi(api);
api.slide = slideApi(api);
api.vote = voteApi(api);

export default api;
