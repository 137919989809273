import {
  HomeOutlined,
  RocketLaunchOutlined,
  FormatListBulleted,
  ListAltOutlined,
  PhotoCamera,
  SportsSoccer,
  Poll,
  Language,
  LocationOn,
  CreditCard
} from '@mui/icons-material';

export default [
  {
    name: 'Anasayfa',
    Icon: HomeOutlined,
    path: '/',
    mainPath: '/',
    isVisible: true
  },
  {
    name: 'Slide Listesi',
    Icon: PhotoCamera,
    path: '/slide/list',
    mainPath: '/slide/list',
    isVisible: true
  },
  {
    name: 'Slide Ekle',
    Icon: ListAltOutlined,
    path: '/slide/add',
    mainPath: '/slide/list',
    isVisible: false
  },
  {
    name: 'Kuponlar',
    Icon: FormatListBulleted,
    path: '/coupon/list',
    mainPath: '/coupon/list',
    isVisible: true
  },
  {
    name: 'Kupon Ekle',
    Icon: FormatListBulleted,
    path: '/coupon/add',
    mainPath: '/coupon/list',
    isVisible: false
  },
  {
    name: 'Maçlar',
    Icon: SportsSoccer,
    path: '/match/list',
    mainPath: '/match/list',
    isVisible: true
  },
  {
    name: 'Maç Ekle',
    Icon: SportsSoccer,
    path: '/match/add',
    mainPath: '/match/list',
    isVisible: false
  },
  {
    name: 'Oylar',
    Icon: Poll,
    path: '/vote/list',
    mainPath: '/vote/list',
    isVisible: true
  },
  {
    name: 'Siteler',
    Icon: Language,
    path: '/site/list',
    mainPath: '/site/list',
    isVisible: true
  },
  {
    name: 'Site Ekle',
    Icon: Language,
    path: '/site/add',
    mainPath: '/site/list',
    isVisible: false
  },
  {
    name: 'Ülkeler',
    Icon: LocationOn,
    path: '/country/list',
    mainPath: '/country/list',
    isVisible: true
  },
  {
    name: 'Ülke Ekle',
    Icon: LocationOn,
    path: '/country/add',
    mainPath: '/country/list',
    isVisible: false
  },
  {
    name: 'Ödeme Yöntemleri',
    Icon: CreditCard,
    path: '/payment/list',
    mainPath: '/payment/list',
    isVisible: true
  },
  {
    name: 'Ödeme Yöntemi Ekle',
    Icon: CreditCard,
    path: '/payment/add',
    mainPath: '/payment/list',
    isVisible: false
  },
  {
    name: 'Deploy',
    Icon: RocketLaunchOutlined,
    path: '/deploy',
    mainPath: '/deploy',
    isVisible: true
  }
];
